import { chakra } from "@chakra-ui/react";

import {
  FaWindowClose,
  FaRegNewspaper,
  FaRegHandshake,
  FaAngleDown,
  FaAngleUp,
  FaExclamationTriangle,
  FaSearchPlus,
  FaSearch,
  FaUserCircle,
  FaMapMarkerAlt,
  FaCheck,
  FaTimes,
  FaRegStar,
  FaStarHalfAlt,
  FaStar,
  FaCalendarCheck,
  FaLink,
  FaRegListAlt,
  FaPiggyBank,
  FaSuitcase,
  FaDollyFlatbed,
  FaRegCalendarTimes,
  FaCertificate,
  FaRegCheckSquare,
  FaRegSquare,
} from "react-icons/fa";
import { HiOutlineKey, HiMenu } from "react-icons/hi";
import {
  BsChatSquareDots,
  BsChatSquareDotsFill,
  BsFillHouseFill,
  BsPeople,
  BsHouse,
} from "react-icons/bs";
import { FiSettings, FiEdit, FiTrendingDown } from "react-icons/fi";
import {
  GiPayMoney,
  GiEarthAmerica,
  GiAlarmClock,
  GiDoubleFish,
} from "react-icons/gi";
import { GrMail } from "react-icons/gr";
import { GoGraph } from "react-icons/go";
import { IoDocumentText, IoRibbonOutline, IoHandLeft } from "react-icons/io5";
import { RiFloodLine } from "react-icons/ri";
import { AiOutlineSafetyCertificate } from "react-icons/ai";

export const ChakraChatBubbles = chakra(BsChatSquareDots);
export const ChakraChatBubblesFill = chakra(BsChatSquareDotsFill);
export const ChakraPeople = chakra(BsPeople);
export const ChakraHouse = chakra(BsHouse);
export const ChakraHouseFill = chakra(BsFillHouseFill);
export const ChakraUpArrow = chakra(FaAngleUp);
export const ChakraExpandSearch = chakra(FaSearchPlus);
export const ChakraSearch = chakra(FaSearch);
export const ChakraStar = chakra(FaRegStar);
export const ChakraDownArrow = chakra(FaAngleDown);
export const ChakraErrorAlert = chakra(FaExclamationTriangle);
export const ChakraSettings = chakra(FiSettings);
export const ChakraKey = chakra(HiOutlineKey);
export const ChakraMenu = chakra(HiMenu);
export const ChakraHandshake = chakra(FaRegHandshake);
export const ChakraHandStop = chakra(IoHandLeft);
export const ChakraDeleteSearch = chakra(FaWindowClose);
export const ChakraWindowClose = chakra(FaWindowClose);
export const ChakraNewspaper = chakra(FaRegNewspaper);
export const ChakraDefaultUser = chakra(FaUserCircle);
export const ChakraEdit = chakra(FiEdit);
export const ChakraDoc = chakra(IoDocumentText);
export const ChakraMail = chakra(GrMail);
export const ChakraMapMarker = chakra(FaMapMarkerAlt);
export const ChakraCheckmark = chakra(FaCheck);
export const ChakraX = chakra(FaTimes);
export const ChakraStarEmpty = chakra(FaRegStar);
export const ChakraStarHalf = chakra(FaStarHalfAlt);
export const ChakraStarFull = chakra(FaStar);
export const ChakraMoneyPay = chakra(GiPayMoney);
export const ChakraCalendar = chakra(FaCalendarCheck);
export const ChakraGraph = chakra(GoGraph);
export const ChakraHouseFlood = chakra(RiFloodLine);
export const ChakraGlobe = chakra(GiEarthAmerica);
export const ChakraAlarmClock = chakra(GiAlarmClock);
export const ChakraDownGraph = chakra(FiTrendingDown);
export const ChakraLink = chakra(FaLink);
export const ChakraList = chakra(FaRegListAlt);
export const ChakraPiggyBank = chakra(FaPiggyBank);
export const ChakraSuitcase = chakra(FaSuitcase);
export const ChakraDolly = chakra(FaDollyFlatbed);
export const ChakraCalendarX = chakra(FaRegCalendarTimes);
export const ChakraDoubleFish = chakra(GiDoubleFish);
export const ChakraSeal = chakra(FaCertificate);
export const ChakraShield = chakra(AiOutlineSafetyCertificate);
export const ChakraRibbon = chakra(IoRibbonOutline);
export const ChakraCheckboxChecked = chakra(FaRegCheckSquare);
export const ChakraCheckbox = chakra(FaRegSquare);
